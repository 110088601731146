
import SUTDTranscript from "./transcript";

const templates = [
  {
    id: "transcript",
    label: "Transcript",
    template: SUTDTranscript
  }
];

export default templates;
